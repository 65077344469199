import { __decorate, __extends } from "tslib";
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { createCustomer } from '../../api/customers';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { isValidEmailFormat } from '../../utils/utils';
import { Dropdown } from '../../components/Dropdown';
import { CustomerType } from '../../models/Customer';
import S from './customers.styl';
var AddCustomerModal = /** @class */ (function (_super) {
    __extends(AddCustomerModal, _super);
    function AddCustomerModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            emailInputError: false,
            passwordInputError: false,
            phoneNumberInputError: false,
            firstNameInputError: false,
            lastNameInputError: false,
            type: CustomerType.referrer,
        };
        _this.onChangeAction = function (e) {
            var name = e.currentTarget.name;
            if (name === 'email') {
                _this.setState({ emailInputError: false });
            }
            if (name === 'password') {
                _this.setState({ passwordInputError: false });
            }
            if (name === 'firstName') {
                _this.setState({ firstNameInputError: false });
            }
            if (name === 'lastName') {
                _this.setState({ lastNameInputError: false });
            }
        };
        _this.isUpdated = function () {
            if (_this.props.isUpdated) {
                _this.props.isUpdated();
            }
        };
        _this.onClose = function () {
            if (_this.props.onClose) {
                _this.props.onClose();
            }
        };
        _this.onTypeChange = function (type) {
            _this.setState({
                type: type,
            });
        };
        return _this;
    }
    AddCustomerModal.prototype.onSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        var elements = event.currentTarget.elements;
        var valid = true;
        var email = elements.email.value;
        var password = elements.password.value;
        var firstName = elements.firstName.value;
        var lastName = elements.lastName.value;
        if (!isValidEmailFormat(email)) {
            this.setState({ emailInputError: true });
            valid = false;
        }
        if (password.length === 0) {
            this.setState({ passwordInputError: true });
            valid = false;
        }
        if (firstName.length === 0) {
            this.setState({ firstNameInputError: true });
            valid = false;
        }
        if (lastName.length === 0) {
            this.setState({ lastNameInputError: true });
            valid = false;
        }
        if (!valid) {
            return;
        }
        var request = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            environment: 'WEB',
            type: this.state.type,
        };
        this.setState({ loading: true });
        createCustomer(request).then(function () {
            _this.setState({ loading: false });
            _this.isUpdated();
        }).catch(function (error) {
            _this.setState({ loading: false });
            alert(error);
        });
    };
    AddCustomerModal.prototype.render = function () {
        var _this = this;
        var _a = this, props = _a.props, state = _a.state;
        var i18n = props.store.i18n;
        var loading = state.loading;
        return (React.createElement(Modal, { className: props.className, onRequestClose: props.onClose },
            React.createElement("div", { className: S.customersAddView },
                React.createElement("p", { className: S.adminsHeaderText }, i18n.customersCreateTitle),
                React.createElement("form", { className: "" + S.addDialogContent, method: "get", action: "/", onSubmit: this.onSubmit, noValidate: true },
                    React.createElement("div", null,
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.loginViewEmailPlaceholder),
                            React.createElement("input", { className: S.addInput + " textInput", name: "email", type: "email", autoComplete: "username", onInput: this.onChangeAction, placeholder: i18n.loginViewEmailPlaceholder })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.emailInputError ? 'inputErrorActive' : '') }, i18n.validationCreateEmailError),
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.loginViewPasswordPlaceholder),
                            React.createElement("input", { className: S.addInput + " textInput", name: "password", type: "password", autoComplete: "current-password", onInput: this.onChangeAction, placeholder: i18n.loginViewPasswordPlaceholder })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.passwordInputError ? 'inputErrorActive' : '') }, i18n.validationCreatePasswordError),
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.customersFirstName),
                            React.createElement("input", { className: S.addInput + " textInput", name: "firstName", onInput: this.onChangeAction, placeholder: i18n.customersFirstName })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.firstNameInputError ? 'inputErrorActive' : '') }, i18n.validationFirstNameError),
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.customersLastName),
                            React.createElement("input", { className: S.addInput + " textInput", name: "lastName", onInput: this.onChangeAction, placeholder: i18n.customersLastName })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.lastNameInputError ? 'inputErrorActive' : '') }, i18n.validationLastNameError),
                        React.createElement(Dropdown, { maxHeight: 190, isFixed: true, title: i18n.customerType, className: "modalDropDown " + S.addInputWrapper + " " + S.dropdown, boxClass: "modalDropDownBox", label: "" + (state.type === CustomerType.referrer ? i18n.customerTypeReferrer : i18n.customerTypeCustomer) },
                            React.createElement(Dropdown.List, null,
                                React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function (_) { return _this.onTypeChange(CustomerType.referrer); } }, i18n.customerTypeReferrer),
                                React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function (_) { return _this.onTypeChange(CustomerType.customer); } }, i18n.customerTypeCustomer)))),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.adminsModalButton, type: "submit", spinner: loading }, i18n.actionCreate),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.adminsModalButton + " " + S.adminsModalCancel, type: "button", spinner: loading, onClick: this.onClose }, i18n.alertModalCancel)))));
    };
    __decorate([
        action.bound
    ], AddCustomerModal.prototype, "onSubmit", null);
    AddCustomerModal = __decorate([
        inject('store'),
        observer
    ], AddCustomerModal);
    return AddCustomerModal;
}(React.Component));
export { AddCustomerModal };
